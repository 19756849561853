import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'

import Layout from '../components/Layout'

import KeymakerWhiteSvg from '../assets/svg/km-white.svg';
import TriangleSvg from '../assets/svg/triangle.svg';
import arrowSvg from '../assets/images/arrow.svg';
import KeymakerLogoTypeSvg from '../assets/svg/km-logo-type-m.svg'
// import portalWingImg from '../assets/images/portal_ring.png';
import verticalLineImg from '../assets/images/dotted-arrow-vertical.png';

import { pageLink, conversionId, googleConversion } from '../utils'

import './index.scss'

const ModalVideo = loadable(() => import('react-modal-video'))
const portalWingImg = '../assets/images/portal_ring.png';

// markup
const IndexPage = () => {
  const [isOpen, setOpen] = useState(false)

  const handleVideoClick = () => {
    setOpen(true)
    googleConversion(conversionId.watchVideo)
  }

  return (
    <Layout
      title="Home | Access the Metaverse | MHTTP"
      pageClassName="page-home"
      description="Explore the Metaverse, travel through virtual worlds and build your own portals with MHTTP. Open source, no coding required, travel the Metaverse now"
    >
      <section className="page-home__hero">
        <div className="page-home__hero__container">
          <h1 className="logo-text">Connecting Everyone in the Metaverse</h1>
          <p>MHTTP empowers you to create portals and go anywhere, anytime in the Metaverse.</p>
          <ModalVideo 
            channel='youtube'
            autoplay 
            isOpen={isOpen} 
            videoId={pageLink.youtubeId} 
            onClose={() => setOpen(false)} 
          />
          <button 
            className="button" 
            onClick={handleVideoClick}>Watch Video</button>
          <a 
            className="button secondary" 
            href={pageLink.portal.create} 
            target="_blank" 
            rel="noreferrer" 
            aria-label="start here"
            onClick={() => googleConversion(conversionId.startHereCocreateTheMetaverse)}
          >
            Get Started
          </a>
        </div>
      </section>      
      <section className="page-home__portocol">
        <div className="page-home__portocol__container">
          <div className="page-home__portocol__container__left">
            <h2>The World’s 1st Metaverse Teleportation Protocol</h2>
            <p>Safeguarding an open, connected and free Metaverse.</p>
            <a 
              className="button" 
              href={pageLink.portal.create} 
              target="_blank" 
              rel="noreferrer" 
              aria-label="start here"
              onClick={() => googleConversion(conversionId.startHereCocreateTheMetaverse)}
            >
              Co-Create the Metaverse
            </a>
          </div>
        </div>
      </section>
      <section className="page-home__vision">
        <div className="page-home__vision__row">
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              Freedom of movement for everyone, everywhere
            </div>
          </div>
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              A connected and seamless experience
            </div>
          </div>
          <div className="page-home__vision__row__cell heading">
            <div className="inner">
              Break down walled gardens
            </div>
          </div>
        </div>
        <div className="arrow">
          <div className="arrow__line">
            <TriangleSvg className="triangle" />
          </div>
          {/* <img className="arrow" src={arrowSvg} alt="arrow"/> */}
        </div>
        <div className="page-home__vision__row">
          <div className="page-home__vision__row__cell body-copy">
            Build and navigate an open Metaverse
          </div>
          <div className="page-home__vision__row__cell  body-copy">
            Instantaneous travel between virtual worlds
          </div>
          <div className="page-home__vision__row__cell  body-copy">
            The future should be co-created and accessible to all
          </div>
        </div>
        <div className="page-home__vision__mobile">
          <div className="page-home__vision__mobile__img-wrapper">
            <img 
              className="page-home__vision__mobile__img-wrapper__img" 
              src={verticalLineImg} 
              alt="vertical line"
            />
          </div>
          <div className="page-home__vision__mobile__texts">
            <div className="page-home__vision__mobile__texts__text freedom">
              <div className="heading">
                Freedom of movement for everyone, everywhere
              </div>
              <div className="body-copy">
                Build and navigate an open Metaverse
              </div>
            </div>
            <div className="page-home__vision__mobile__texts__text connected">
              <div className="heading">
                A connected and seamless experience
              </div>
              <div className="body-copy">
                Instantaneous travel between virtual worlds
              </div>
            </div>
            <div className="page-home__vision__mobile__texts__text garden">
              <div className="heading">
                Break down walled gardens
              </div>
              <div className="body-copy">
                The future should be co-created and accessible to all
              </div>
            </div>
          </div>
        </div>
      </section>  
      <section className="page-home__users">
        <div className="page-home__users__container">
          <div className="page-home__users__container__element">
            <div className="page-home__users__container__element__text creators">
              <h2 className="user-heading">CREATORS</h2>
              <p className="user-body">Designing your portal is quick and easy, with no coding required. Drive traffic to your website or virtual world in just a few simple steps.</p>
            </div>
            <a 
              className="button onDark" 
              href={pageLink.portal.create} 
              target="_blank" 
              rel="noreferrer" 
              aria-label="create a portal"
              // onClick={() => googleConversion(conversionId.startHereCocreateTheMetaverse)}
            >
              Create a Portal
            </a>
          </div>
          <div className="page-home__users__container__element__photo">
            {/* <img src={portalWingImg} alt="portal wing" /> */}
            <StaticImage
              src={portalWingImg}
              alt="portal wing"
              placeholder="none"
              // layout="fixed"
              // width={200}
              // height={200}
            />
          </div>
          <div className="page-home__users__container__element">
            <div className="page-home__users__container__element__text developers">
              <h2 className="user-heading">DEVELOPERS</h2>
              <p className="user-body">Explore MHTTP's open standard and open source implementation to connect with other virtual worlds and grow your audience.</p>
            </div>
            <a 
              className="button onDark" 
              href={pageLink.portal.create} 
              target="_blank" 
              rel="noreferrer" 
              aria-label="start building"
              // onClick={() => googleConversion(conversionId.startHereCocreateTheMetaverse)}
            >
              Start Building
            </a>
          </div>
        </div>
      </section>
      <section className="page-home__cta">
        <div className="page-home__cta__container">
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">MHTTP Community</h2>
              <p className="cta-body">Get the latest news and updates via our channels including Discord, Telegram, Twitter and more.</p>
            </div>
            <Link 
              className="button secondary" 
              to={ pageLink.community}
              onClick={() => googleConversion(conversionId.getConnected)}
            >
              Get Connected
            </Link>
          </div>
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">Developer Resources</h2>
              <p className="cta-body">Documentation, protocol specification, demos, tutorials and more to get you up to speed.</p>
            </div>
            <Link 
              className="button secondary" 
              to={ pageLink.developers }
              onClick={() => googleConversion(conversionId.startBuilding)}
            >
              Start Building
            </Link>
          </div>
          <div className="page-home__cta__container__element">
            <div className="page-home__cta__container__element__text">
              <h2 className="cta-heading">Support for Creators</h2>
              <p className="cta-body">Get to know more about MHTTP, learn how to build portals and start creating. No coding required.</p>
            </div>
            <Link 
              className="button secondary" 
              to={ pageLink.about.page }
              onClick={() => googleConversion(conversionId.learnMore)}
            >
              Learn More
            </Link>
          </div>
        </div>   
      </section>      
    </Layout>
  )
}

export default IndexPage
